var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "name",
                      label: _vm.$t("Nome do Subgrupo"),
                      placeholder: _vm.$t("Nome"),
                    },
                    model: {
                      value: _vm.filters.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "name", $$v)
                      },
                      expression: "filters.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "group-id",
                      type: "vue-select",
                      label: _vm.$t("Grupo"),
                      placeholder: _vm.$t("Grupo"),
                      options: _vm.getComboGroups,
                    },
                    model: {
                      value: _vm.filters.groupId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "groupId", $$v)
                      },
                      expression: "filters.groupId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "subgroup-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(_vm.$tc("SUBGROUP.NAME")),
              fields: _vm.fields,
              items: _vm.productGroups,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getSubgroups,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(active)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.active },
                    }),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "is-active": row.item.active,
                        "show-update": _vm.$can("Update", "ProductSubgroup"),
                        "show-delete": _vm.$can("Delete", "ProductSubgroup"),
                        "show-activate": _vm.$can(
                          "Activate",
                          "ProductSubgroup"
                        ),
                        "show-deactivate": _vm.$can(
                          "Deactivate",
                          "ProductSubgroup"
                        ),
                      },
                      on: {
                        update: function ($event) {
                          return _vm.showUpdateGroupSidebar(row)
                        },
                        delete: function ($event) {
                          return _vm.deleteGroup(row.item)
                        },
                        activate: function ($event) {
                          return _vm.activateDeactivateGroup(row)
                        },
                        deactivate: function ($event) {
                          return _vm.activateDeactivateGroup(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "product-subgroup-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("product-subgroup-sidebar", {
        ref: "subgroup_sidebar",
        on: { save: _vm.filterData },
      }),
      _vm.$can("Create", "ProductSubgroup")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Subgrupo") },
            on: { click: _vm.showCreateGroupSidebar },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }